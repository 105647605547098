import { useEffect } from 'react'
import tw from 'twin.macro'
import parse from 'html-react-parser'
import { GatsbyImage } from 'gatsby-plugin-image'

import FlexSection from '@components/FlexSection'
import Button from '@components/Button'
import Title from '@components/Title'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_TextWithImageAndCta,
  WpPrograma_Acfpagefields_Content_TextWithImageAndCta
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_TextWithImageAndCta
  | WpPrograma_Acfpagefields_Content_TextWithImageAndCta

const TextWithImageAndCta = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { image, text, title, bgColor, cta, invertColumns, tagTitle } = data

  const colors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      title={!invertColumns ? title : true}
      tagTitle={tagTitle}
      colors={colors}
      index={index}
      alignTitle="left"
      id={title || ''}
      paddingTop
      paddingBottom
      tabs={tabs}
      decorator={[
        {
          color: 'white',
          width: 430,
          x: -1,
          y: 2
        }
      ]}
    >
      <div
        tw="flex flex-wrap justify-between pt-8"
        css={[invertColumns && tw`flex-row-reverse`]}
      >
        <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full overflow-auto h-full mb-4 lg:mb-0">
          {invertColumns && (
            <Title
              tag="h1"
              title={title}
              color={colors ? colors.text : 'dark'}
            />
          )}

          <div
            tw="lg:ml-4 prose mb-6!"
            css={[colors.text === 'light' && tw`text-white!`]}
          >
            {text && parse(text)}
          </div>
          <div tw="lg:ml-4">
            <Button
              color={colors.text === 'light' ? 'yellow' : 'orange'}
              text={cta.title}
              link={cta.url}
              blank={cta.target === '_blank'}
            />
          </div>
        </div>
        <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full overflow-auto h-full mb-4 lg:mb-0">
          {image && (
            <GatsbyImage
              image={image.localFile.childImageSharp['mid']}
              alt={image.altText}
              tw="rounded-tl-[120px] rounded-br-[120px]"
            />
          )}
        </div>
      </div>
    </FlexSection>
  )
}

export default TextWithImageAndCta
